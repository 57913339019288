// intersection observer
// https://webdesign.tutsplus.com/tutorials/how-to-intersection-observer--cms-30250
// https://github.com/matuzo/IntersectionObserver-Examples
// https://www.hweaver.com/intersection-observer-single-page-navigation/

export function videoObserver() {
  if ('IntersectionObserver' in window) {
    // https://uploadcare.com/blog/intersection-observer-guide/
    let video = document.querySelector('video');

    if (video !== null) {
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio !== 1 && !video.paused) {
              video.pause();
            } else {
              video.play();
            }
          });
        },
        { root: null, threshold: 1 }
      );
      observer.observe(video);
    }
  }
}
